@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'flowbite';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.phone-input input {
  border: 0px;
}

.phone-input input:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
.white-text {
  color: white;
}
#remote_video_element_id {
  width: 100% !important;
  height: 100%;
}

.input {
  border-bottom: 2px solid #4f9d4a;
  border-top: none;
  border-left: none;
  border-right: none;
}
.input:focus {
  border: none !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
}

iframe {
  display: none;
}
